import Vue from 'vue'
import VueRouter from 'vue-router'
// @ts-ignore
import News from '../pages/News.vue'
// @ts-ignore
import Minutes from '../pages/Minutes.vue'
// @ts-ignore
import Info from '../pages/Info.vue'
// @ts-ignore
import Contact from '../pages/Contact.vue'
// @ts-ignore
import Login from '../pages/Login.vue'
// @ts-ignore
import Home from '../pages/Home.vue'
// @ts-ignore
import Private from '../pages/Private.vue'
// @ts-ignore
import Gallery from '../pages/Gallery.vue'

Vue.use(VueRouter);

const routes = [
    {path: '/', name: 'Home', meta: {title: "Stonebrook HOA"}, component: Home},
    {path: '/news', name: 'news', meta: {title: "Stonebrook HOA - News"}, component: News},
    {path: '/minutes', name: 'minutes', meta: {title: "Stonebrook HOA - Minutes"}, component: Minutes},
    {path: '/info', name: 'info', meta: {title: "Stonebrook HOA - Info"}, component: Info},
    {path: '/contact', name: 'contact', meta: {title: "Stonebrook HOA - Contact"}, component: Contact},
    {path: '/login', name: 'login', meta: {title: "Stonebrook HOA - Login"}, component: Login},
    {path: '/private', name: 'private', meta: {title: "Stonebrook HOA - Private"}, component: Private},
    {path: '/gallery', name: 'gallery', meta: {title: "Stonebrook HOA - Gallery Administration"}, component: Gallery}
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to?.meta?.title;
    next();
});

export default router
