<template>
  <div class="wrapper">
    <div class="header">
      <div class="container">
        <div class="hero">
          <div class="hero-body">
            <router-link to="/">
              <h1 class="title">Stonebrook Homeowners Association</h1>
            </router-link>
          </div>
        </div>

        <nav class="navbar" role="navigation" data-test="menu-bar" aria-label="main navigation is-fullwidth">
          <b-navbar>
            <template slot="end">
              <b-navbar-item data-test="menu-item" tag="router-link" :to="{path: '/'}">Home</b-navbar-item>
              <b-navbar-item data-test="menu-item" tag="router-link" :to="{path: '/news'}">News</b-navbar-item>
              <b-navbar-item data-test="menu-item" tag="router-link" :to="{path: '/minutes'}">Minutes</b-navbar-item>
              <b-navbar-item data-test="menu-item" tag="router-link" :to="{path: '/info'}">Info</b-navbar-item>
              <b-navbar-item data-test="menu-item" tag="router-link" :to="{path: '/contact'}">Contact</b-navbar-item>
              <b-navbar-item data-test="menu-item" tag="router-link" v-if="loggedIn" :to="{path: '/private'}">Private</b-navbar-item>
              <b-navbar-item data-test="menu-item" :href="loggedIn ? '/logout' : '/login'">{{ loggedIn ? 'Logout' : 'Login' }}</b-navbar-item>
            </template>
          </b-navbar>
        </nav>

      </div>
    </div>
    <div class="content">
      <router-view/>
    </div>
    <div class="footer">
      <div class="container">
        © {{ year }} Stonebrook Homeowners Association
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      year: new Date().getUTCFullYear(),
      showNavbar: false,
      loading: true,
      loggedIn: false,
      loggedInSubscriptions: []
    };
  },
  mounted() {
    this.loadStatus();
  },
  methods: {
    onLogin(callback) {
      this.loggedInSubscriptions.push(callback)
    },
    loadStatus() {
      this.axios.get("/api/users/me")
          .then(response => {
            this.loggedIn = response.data;
            this.loggedInSubscriptions.forEach(callback => callback(response.data))
            this.loading = false;
          });
    }
  }
}
</script>

<style lang="scss">
html {
  height: 100%;

  body {
    height: 100%;

    .wrapper {
      display: flex;
      flex-flow: column;
      align-items: stretch;
      height: 100%;

      .header {
        background-color: #2a0d07;
        color: white;

        .hero-body {
          padding-top: 24px;
          padding-right: 20px;
          padding-left: 20px;
          padding-bottom: 24px;
        }

        a, a:hover, link, link:hover, .title {
          color: white;
        }

        .navbar {
          font-weight: bold;
          background-color: transparent;
          width: 100%;

          .navbar-menu {
            background-color: #2a0d07;
          }

          .navbar-link:not(.is-arrowless)::after {
            border-color: #F88F26;
          }

          a, a:hover, link, link:hover, .navbar-item, .navbar-link {
            color: #F88F26;
            background-color: #2a0d07;
          }

          a:hover {
            color: #a25123;
          }
        }
      }

      .content {
        flex-grow: 1;
        width: 100%;
        padding-top: 20px;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 20px;

        .title {
          border-bottom: 1px solid #888888;
          padding-bottom: 10px;
        }

        .edit {
          cursor: pointer;
          color: #1d72aa;
        }
      }

      .footer {
        background-color: #120900;
        width: 100%;
        color: white;
        padding-top: 20px;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 20px;
      }
    }
  }
}
</style>
