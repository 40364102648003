<template>
  <content-wrapper title="Home">
    <p>The Stonebrook Estates Homeowners Association, Inc. (HOA) is a not for profit Wisconsin corporation established
      in 1995 for the purpose of maintaining, preserving, and architecturally controlling the residential lots and
      outlets of
      Plat Stonebrook Estates, a Midland development, located in the Town of Middleton, Dane County, Wisconsin.</p>
    <p>The HOA is responsible for the management of 53 acres of Outlot land. Its affairs are managed by a Board of
      Directors. The purpose of the Association includes to promote and maintain natural open space, prevent the growth
      of noxious
      weeds and invasive species, and keep the Outlots in good, clean, attractive, and sanitary condition. The
      Association’s Common expenses are assessed annually and information is shared through its website, group list
      serve, and annual
      meeting. Its residents attend Sunset Ridge Elementary, Glacier Creek Middle, and the Middleton High Schools.</p>
    <div class="has-text-centered small-images" v-if="imagesLoaded">
      <a @click="selectImage(images.indexOf(image))" v-for="image in images">
        <div :class="{'active': isActive(image)}" :style="{background: `url('${image}')`}"/>
      </a>
    </div>
    <div v-if="imagesLoaded" class="has-text-centered"><img alt="main image" :class="{'hide': hide}" :src="imageSrc"/></div>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper";
import Files from "@/components/Files";

export default {
  name: "News",
  components: {ContentWrapper, Files},
  methods: {
    cyclePictureJob() {
      setTimeout(() => {
        if (this.nextCycle < this.now()) {
          this.nextPicture();
          this.nextCycle = this.now() + 5000;
        }
        this.cyclePictureJob();
      }, 500);
    },
    nextPicture() {
      this.selectImage((this.image + 1) % this.images.length)
    },
    selectImage(imageIndex) {
      if (!this.loading) {
        this.hide = true;
        this.loading = true;
        setTimeout(() => {
          this.image = imageIndex
          setTimeout(() => {
            this.nextCycle = this.now() + 5000;
            this.hide = false;
            setTimeout(() => {
              this.loading = false;
            }, 500);
          }, 100);
        }, 500);
      }
    },
    now() {
      return new Date().getTime();
    },
    isActive(imageUrl) {
      return this.imageSrc === imageUrl;
    }
  },
  data() {
    this.axios.get("/api/gallery")
        .then(response => {
          this.images = response.data.map(image => image.url)
        })

    return {
      showArchive: false,
      hide: false,
      loading: false,
      nextCycle: null,
      image: 0,
      images: []
    }
  },
  computed: {
    imageSrc() {
      return this.images[this.image];
    },
    imagesLoaded() {
      return this.images.length > 0
    }
  },
  mounted() {
    this.nextCycle = this.now() + 8000;
    this.cyclePictureJob();
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 10px;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;

  &.hide {
    opacity: 0;
  }

  @include mobile {
    max-width: 100%;
  }
}

.small-images {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    margin-bottom: 5px;
    margin-right: 5px;

    div {
      width: 70px;
      height: 70px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      float: left;
      border-radius: 5px;

      &.active {
        opacity: 0.5;
      }
    }
  }
}
</style>
